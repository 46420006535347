import React, { useContext } from 'react'
import { AccountContext, FormContext } from 'utils/context'
import Vehicles from 'forms/fields/Vehicles'

const AllVehicles = ({ value, exclude, ...props }) => {
  
  let { vehicles, policies } = useContext(AccountContext)
  const { data } = useContext(FormContext)

  let updatedPolices = policies.filter(p => p.lineitem_whitelist.includes(data.type))
  let finalChoices = []

  vehicles.forEach(v => {
    if(updatedPolices.some(p => v.policy === p.id)){
      finalChoices.push([`${v.vehicle_id}`, `${v.full_vehicle_name}`])
    }
  })

  if (exclude && exclude.id) {
    finalChoices = finalChoices.filter(ch => ch[0] !== exclude.id)
  }

  return (
    <Vehicles
      {...props}
      value={value}
      choices={finalChoices}
    />
  )
}

export default AllVehicles