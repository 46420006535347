import { parseISO, isBefore, subMonths } from 'date-fns'

export const provinceConfig = (maxMonths, maxKms) => {
  return {
    maxMonths,
    maxKms,
  }
}

// pass in max months prior to purchase and max kms

const config = {
  'pembridge': {
    'ON':   provinceConfig(24, 5000),
    'AB':   provinceConfig(30, 5000),
    'NB':   provinceConfig(24, 5000),
    'NS':   provinceConfig(24, 5000),
    'NL':   provinceConfig(null, null),
    'PE':   provinceConfig(null, null),
  },
  'zenith': {
    'ON':   provinceConfig(48, 10000),
    'AB':   provinceConfig(24, 5000),
    'NB':   provinceConfig(48, 1000000), // any kms
    'NS':   provinceConfig(48, 1000000), // any kms
    'NL':   provinceConfig(48, 1000000), // any kms
    'PE':   provinceConfig(48, 1000000), // any kms
  },
  'traders': {
    'ON':   provinceConfig(24, 5000),
    'AB':   provinceConfig(30, 10000),
    'NB':   provinceConfig(24, 10000),
    'NS':   provinceConfig(30, 10000),
    'NL':   provinceConfig(30, 10000),
    'PE':   provinceConfig(30, 10000),
  },
  'travelers': {
    'ON':   provinceConfig(36, 1000000), // any kms
    'AB':   provinceConfig(30, 1000000), // any kms
    'NB':   provinceConfig(36, 1000000), // any kms
    'NS':   provinceConfig(36, 1000000), // any kms
    'NL':   provinceConfig(36, 1000000), // any kms
    'PE':   provinceConfig(36, 1000000), // any kms
  },
  'wawanesa': {
    'ON':   provinceConfig(36, 5000),
    'AB':   provinceConfig(36, 5000),
    'NB':   provinceConfig(null, null),
    'NS':   provinceConfig(null, null),
    'NL':   provinceConfig(null, null),
    'PE':   provinceConfig(null, null),
  },
  'royal': {
    'ON':   provinceConfig(48, 10000),
    'AB':   provinceConfig(30, 10000),
    'NB':   provinceConfig(48, 10000),
    'NS':   provinceConfig(48, 10000),
    'NL':   provinceConfig(48, 10000),
    'PE':   provinceConfig(24, 1000000), // any kms
  },
  'intact': {
    'ON':   provinceConfig(24, 5000),
    'AB':   provinceConfig(30, 5000),
    'NB':   provinceConfig(null, null),
    'NS':   provinceConfig(null, null),
    'NL':   provinceConfig(null, null),
    'PE':   provinceConfig(null, null),
  },
  'gore': {
    'ON':   provinceConfig(24, 5000),
    'AB':   provinceConfig(null, null),
    'NB':   provinceConfig(null, null),
    'NS':   provinceConfig(null, null),
    'NL':   provinceConfig(null, null),
    'PE':   provinceConfig(null, null),
  },
  'caa': {
    'ON':   provinceConfig(60, 5000),
    'AB':   provinceConfig(null, null),
    'NB':   provinceConfig(null, null),
    'NS':   provinceConfig(null, null),
    'NL':   provinceConfig(null, null),
    'PE':   provinceConfig(null, null),
  },
  'econ': {
    'ON':   provinceConfig(12, 1000000), // any kms
    'AB':   provinceConfig(12, 1000000), // any kms
    'NB':   provinceConfig(12, 1000000), // any kms
    'NS':   provinceConfig(12, 1000000), // any kms
    'NL':   provinceConfig(null, null),
    'PE':   provinceConfig(12, 1000000), // any kms
  },
}

const carrierMap = {
  'pembridge': ['PAF', 'PE24', 'PEM'],
  'zenith': ['ZENI', 'ZWEB', 'LOMI'],
  'traders': ['GA', 'GA2', 'TRG', 'TRG2', 'SAY', 'SR', 'NULL'],
  'travelers': ['DOC', 'DOC2', 'DOC3', 'DOM2', 'CIC'],
  'wawanesa': ['WAWA'],
  'royal': ['ROY', 'WEST'],
  'intact': ['HAL', 'HAL3', 'GC', 'GC3', 'WU'],
  'gore': ['GORE'],
  'caa': ['CAA', 'CAA2', 'CAA3'],
  'econ': ['ECON'],
}

class waiverDepreciationVisible {
  constructor(policies = null, data = null) {
    this.policies = policies
    this.data = data

    this.config = null

    this.errors = {
      generic: 'Could not determine waiver of depreciation',
      carrier: 'Waiver of Depreciation: error with carrier',
      config: 'Waiver of Depreciation: error with config',
      policy: 'Waiver of Depreciation: error with policy',
      required: 'Waiver of Depreciation: error with required data',
      used: 'Waiver of Depreciation: vehicle not new or demo',
      collisionOrComp: 'Waiver of Depreciation: no collision or comprehensive',
      kms: 'Waiver of Depreciation: error with kms',
      months: 'Waiver of Depreciation: error with max months',
    }

    this.debug = false
  }

  getCarrier() {
    const carrier = this.policy()['carrier_code']
    for (let [key, value] of Object.entries(carrierMap)) {
      if (value.includes(carrier)) {
        return key
      }
    }
    throw new Error(this.errors.carrier)
  }

  getConfig() {
    const carrier = this.getCarrier()
    const province = this.data['registered_province'] ? this.data['registered_province'] : this.policy().province
    const conf = config[carrier][province]
    if (!conf) {
      throw new Error(this.errors.config)
    }
    return conf
  }

  policy() {
    const pol = this.policies.find(p => Number(p.id) === Number(this.data.policy))
    if (pol === undefined) {
      throw new Error(this.errors.policy)
    }
    return pol
  }

  hasPoliciesAndData() {
    if (this.policies === null || this.data === null) {
      throw new Error(this.errors.required)
    }
  }

  newOrDemo() {
    if (this.data['vehicle_condition'] === 'used') {
      throw new Error(this.errors.used)
    }
  }
  
  collisionAndComprehensive() {
    const { coverage_coll, coverage_comp } = this.data
    if (coverage_coll === 'not_included' || coverage_comp === 'not_included') {
      throw new Error(this.errors.collisionOrComp)
    }
  }

  checkKms() {
    const kms = this.data.kms_at_purchase
    if (this.data['vehicle_condition'] === 'demo') {
      if (kms > this.config.maxKms) {
        throw new Error(this.errors.kms)
      }
    }
  }

  checkDate() {
    if (this.data.type !== 'vehicle_add_quote' && this.data.type !== 'vehicle_replace_quote') {
      if (!this.data.purchase_date) {
        throw new Error(this.errors.required)
      }
      const today = new Date()
      const max = subMonths(today, this.config.maxMonths)
      const purchase = parseISO(this.data.purchase_date)

      if (isBefore(purchase, max)) {
        throw new Error(this.errors.months)
      }
    }
  }

  checkRequestType() {
    if (this.data.type === 'reduce_coverage' || this.data.type === 'reinstate_coverage' || this.data.type === 'vehicle_remove') {
      throw new Error(this.errors.required)
    }
  }
  
  show() {
    try {

      this.hasPoliciesAndData()
      this.newOrDemo()
      this.collisionAndComprehensive()
      
      this.config = this.getConfig()
      this.checkKms()
      this.checkDate()
      this.checkRequestType()

      return { show: true, message: null }

    } catch (e) {
      
      this.debug && console.log(e)
      return { 
        show: false,
        message: e.message,
      }
    
    }
  }
}

export default waiverDepreciationVisible