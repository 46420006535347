import React, { useContext } from 'react'
import ConfirmCard from 'forms/ConfirmCard'
import RequesterDetails from 'forms/fieldsets/general/RequesterDetails'
import PolicyChangesConfirm from 'forms/fieldsets/general/PolicyChangesConfirm'
import OtherDriversConfirm from 'forms/fieldsets/general/OtherDriversConfirm'
import RequestDisclaimer from 'components/RequestDisclaimer'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import { vehicleRemoveReasons } from 'forms/utils/vars'
import { useTranslation } from 'react-i18next'
import { removeOrReduce, vehicleReduceReasons, vehicleReduceDuration, vehicleReduceAmount } from 'forms/utils/vars'


const VehicleRemoveConfirm = () => {

  const { t } = useTranslation()
  let form = useContext(FormContext)
  let acct = useContext(AccountContext)

  const removedVehicle = acct.vehicles.find(v =>
    String(v.vehicle_id) == form.data['vehicle_id']  // eslint-disable-line
  ) || {}

  let removeReason = vehicleRemoveReasons.find(r => r[0] === form.data['remove_reason'])[1]
  const removeReasonStorage = form.data['remove_reason'] === 'storage'
  const reduceCoverageSelected = form.data['reduce_or_remove'] === 'reduce_coverage'
  
  let reduceReason, duration, amount
  if (reduceCoverageSelected) {
    reduceReason = form.data['reduce_reason'] === 'other' ? form.data['reduce_reason_other'] : vehicleReduceReasons.find(r => r[0] === form.data['reduce_reason'])[1] 
    duration = vehicleReduceDuration.find(d => d[0] === form.data['reduction_duration'])
    amount = vehicleReduceAmount["other_carriers"].find(a => a[0] === form.data['requested_deductible'])
  }
  
  
  return (
    <fieldset>
      <RequestDisclaimer />
      <RequesterDetails />
      <ConfirmCard title={t("Vehicle to Remove")} slug="remove-reason" id={form.data.id}>
        <ul>
          <li>
            {removedVehicle.full_vehicle_name}
          </li>
          <li>
            <small>{t('Reason for Removing')}</small>
            {removeReason}
          </li>
          {removeReasonStorage &&
            <li>
              <small>{t('Selected change type')}</small>
              {removeOrReduce.find(r => r[0] === form.data['reduce_or_remove'])[1]}
            </li> 
          }
          {reduceCoverageSelected &&
            <>
              <ul className="InfoList">
                <li>
                  <small>{t('Reduce reason')}</small>
                  {reduceReason}
                </li>
                <li>
                  <small>{t('Duration of reduction')}</small>
                  {duration[1]}
                </li>
                <li>
                  <small>{t('Requested deductible amount')}</small>
                  {amount[1]}
                </li>
                <li>
                  <small>{t('Rebuilt or salvaged')}</small>
                  {form.data['rebuilt_salvaged'] ? t('Yes') : t('No')}
                </li>
              </ul>
            </>
          }
        </ul>
      </ConfirmCard>
      <PolicyChangesConfirm />
      <OtherDriversConfirm />
    </fieldset>
  )
}

export default VehicleRemoveConfirm