import React, { useState, useEffect } from 'react';
import CloseIcon from 'utils/icons/Close';
import Button from 'components/Buttons/Button';
import './style.scss'
import { useTranslation } from 'react-i18next'

const VideoModal = ({ videoUrl, videoTitle, children }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  if (!isOpen) {
    return (
      <Button onClick={openModal} id='video_modal_open'>
        {children}
      </Button>
    );
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-inner">
          <h2 className="modal-title">{videoTitle}</h2>
          <div className="video-container">
            <video src={videoUrl} title={videoTitle} controls>
              {t("Your browser does not support this feature.")}
            </video>
          </div>
          <Button 
            onClick={closeModal}
            className="close-button"
            aria-label="Close video"
          >
            <CloseIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
