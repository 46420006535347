import { useContext, useEffect } from 'react'
import { AccountContext, AppContext, FormContext } from 'utils/context'
import Radio from 'forms/fields/Radio'
import SlideToggle from 'forms/fields/SlideToggle'
import { prettyDate } from 'utils/helpers'
import { useTranslation } from 'react-i18next'
import Label from 'forms/fields/Label'

const RequestQuote = () => {
  const { t } = useTranslation()
  const { policies, drivers } = useContext(AccountContext)
  const { data, setFieldValue, clearFieldError } = useContext(FormContext)
  const { tenant } = useContext(AppContext)

  useEffect(() => {
    if (data['reason_for_cancellation'] === 'sold_vehicle_or_property' || !tenant.show_quote_required_question) {
      setFieldValue('quote_required', false)
    }
  }, []) // eslint-disable-line

  const quoteCallback = value => {
    if (value === true) {
      setFieldValue('cancellation_consent_accepted', null)
      clearFieldError('cancellation_consent_accepted')
    }
  }

  const lobTextMap = {
    'auto': t('auto'),
    'hab': t('property')
  }

  let cancellingPolicyTypes = new Set([]);

  for (let i = 0; i < policies.length; i++) {
    if (data['cancelling_policies'].includes(policies[i].policy_id)) {
      cancellingPolicyTypes.add(lobTextMap[policies[i].lob])
    } 
  }

  cancellingPolicyTypes = Array.from(cancellingPolicyTypes);

  let requesterName = data['requester_other'];

  let driverRequester = drivers.find(driver => driver.driver_id.toString() === data['requester'])

  if (driverRequester !== undefined){
    requesterName = driverRequester.full_name
  }

  return (
    <fieldset>
      {
        data['reason_for_cancellation'] !== 'sold_vehicle_or_property' && tenant.show_quote_required_question && 
        <Radio
          name="quote_required"
          label={t('Would you be open to receiving a quote to possibly save you money?')}
          choices={[[false, t('No')], [true, t('Yes')]]}
          ChangeCallback={quoteCallback}
        />
      }
      {
        data['quote_required'] === true &&
        <span data-testid="quote-required-yes"><b>{t('Excellent!')}</b> {t('We will have a broker email you a new quote once completed.')}</span>
      }
      {
        data['quote_required'] === false &&
        <SlideToggle
          name="cancellation_consent_accepted"
          label={t('readAndUnderstandCancel', { requester: requesterName })}
          MainLabel={t('accordingToTerms', { requesterName: requesterName, policyType: cancellingPolicyTypes.join('/'), date: prettyDate(data['effective_date'], 'long') })}
        />
      }
      {
        data['cancellation_consent_accepted'] === true &&
        <Label 
          HtmlLabel={
            <>
              {t('A few things to note:')}
              <ul>
                <li>{t('We may reach out to you for further clarification or should we require more information to process your cancellation.')}</li>
                <li>{t('Cancelling on any date, other than your renewal date, may result in a midterm cancellation penalty.')}</li>
                <li>{t('Payments within the next 10 business days may still be withdrawn from your account and if warranted, a refund will be issued.')}</li>
                <li>{t("If you're only cancelling your property OR auto, you may lose the combined policy discount.")}</li>
              </ul>
            </>
          }
        />
      }
    </fieldset>
  )
}

export default RequestQuote