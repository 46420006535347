import Policies from 'forms/fields/Policies'
import { useTranslation } from 'react-i18next'
import DateChooser from 'forms/fields/DateChooser'
import { addDays, startOfTomorrow } from 'date-fns'

const RequestType = () => {

  const { t } = useTranslation()

  return (
    <fieldset>
      <DateChooser
        minDate={startOfTomorrow(new Date())}
        maxDate={addDays(new Date(), 30)}
        name="effective_date"
        label={t('What date do you want this change to take place?')}
        HelpCopy={<strong>{t('If your payment is scheduled to come out within the next 7-10 business days, please be prepared for it come out of your old account')}</strong>}
        IncludeYear
      />
      <Policies
        name="requested_policies"
        label={t('Which of your policies does this change apply to? (select all that apply)')}
        AllowMultiple
      />
    </fieldset>
  )
}

export default RequestType