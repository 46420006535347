import React, { useContext, useState, useCallback } from 'react'
import * as Sentry from '@sentry/browser'
import Tile from 'components/Tile'
import Text from 'forms/fields/Text'
import Radio from 'forms/fields/Radio'
import Select from 'forms/fields/Select'
import JasmineError from 'forms/fieldsets/general/JasmineError'
import PropertyIcon from 'utils/icons/Property'
import { FormContext } from 'utils/context'
import { AccountContext } from 'utils/context'
import { validPostal, processedAddresses } from 'forms/utils/helpers'
import { provinceChoices } from 'utils/vars'
import { postRequest } from 'utils/requests'
import { postalCode } from 'forms/utils/cleans'
import { useTranslation } from 'react-i18next'

const AddressCard = ({ unit, number, street, city, province }) => {
  const { t } = useTranslation()
  let item = province ? provinceChoices.find(i => i[0] === province) : {}
  return (
    <Tile className="VehicleToAdd" Icon={PropertyIcon} IconName="PropertyIcon">
      <div className="TileHeader">
        <strong>{t('Your New Address')}</strong>
      </div>
      <div className="TileBody">
        <address className="NewAddress uppercase">
          {unit && `#${unit},`} {number} {street}<br />
          {city}, {item[1] || province}
        </address>
      </div>
    </Tile>
  )
}

const AddressLookup = props => {

  const { data, setFieldValue } = useContext(FormContext)
  const { policies } = useContext(AccountContext)
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [streets, setStreets] = useState([])
  const [lookupError, setLookupError] = useState(false)
  const [helpTextCopy, sethelpTextCopy] = useState("")

  const goodAddressLookup = useCallback(({ streets, city, province }) => {
    setStreets(streets)
    if (streets.length === 1) {
      setFieldValue('street_name', streets[0][0])
    }
    setFieldValue('municipality', city)
    setFieldValue('province', province)
    setIsLoading(false)
  }, [setFieldValue])

  const fetchAddress = useCallback(postal => {
    setLookupError(false)
    if (validPostal(postal)) {
      setIsLoading(true)
      postRequest('/jasmine/postal_code/addresses/', {
        postal_code: postal,
      })
        .then(res => processedAddresses(res))
        .then(res => goodAddressLookup(res))
        .catch(err => {
          Sentry.captureException(err)
          setLookupError(true)
          setIsLoading(false)
        })
    }
  }, [goodAddressLookup])
  
  const updateHelpText = value => {
    if (value) {
      sethelpTextCopy(t("Due to the number of details required a broker will be reaching out to you to gather the information."))
    } else {
      sethelpTextCopy(t("Your file will be reviewed and if we have any questions we will reach out."))
    }
    
  }

  const hasAddress = data['street_name'] || data['municipality'] || data['province']
  const hasHabPol = policies.find(p => p.lob === 'hab') ? true : false
  const isAddressChange = data.type === 'address_change'

  return (
    <fieldset>
      <Text
        name="postal_code"
        label={props.label || t('Postal code')}
        HelpCopy={props.help}
        ChangeCallback={fetchAddress}
        Loading={isLoading}
        cleans={[postalCode]}
      />
      {lookupError ? (
        <JasmineError type="Postal Code" />
      ) : hasAddress ? (
        <>
          <div className="clearfix">
            {streets.length > 1 && (
              <Select
                name="street_name"
                label={t('Street Name')}
                choices={streets}
              />
            )}
            <Text name="unit_number" label={t('Unit Number')} HalfLeft Optional />
            <Text name="street_number" label={t('Street Number')} HalfRight />
            { hasHabPol && isAddressChange && 
              <Radio 
                name="change_property_address" 
                label={t('Do you also need to change your address on your property policy?')} 
                choices={[[true, t('Yes')], [false, t('No')]]}
                ChangeCallback={updateHelpText}
                HelpCopy={helpTextCopy} 
              /> 
            }
          </div>
          <AddressCard
            unit={data['unit_number']}
            number={data['street_number']}
            city={data['municipality']}
            province={data['province']}
            street={streets.length === 1 ? streets[0][0] : data['street_name']}
          />
        </>
      ) : null}
    </fieldset>
  )
}

export default AddressLookup